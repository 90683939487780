import type { ConnectorJson, ConnectorSerialized, ConnectorValues } from './Connector'
import Connector from './Connector'
import Position, { type PositionJson, type PositionSerialized } from './Position'

export const MAX_RUNS_DEFAULT = 3

/** type de valeur possible pour un paramètre de section */
export type ParamValue = string | number | boolean | (string | number | boolean)[]

/**
 * Les params connus du Player (s’ils existent leur type est imposé)
 */
export interface GeneralParamsValues {
  titre?: string
  limite?: number
  nbQuestions?: number
}

/**
 * Les valeurs des params exportées de la section dans le graphe (le type des propriétés connues du player sont imposés)
 */
export type ParamsValues = Record<string, ParamValue> & GeneralParamsValues

/**
 * Objet suffisant pour créer un GraphNode
 */
export interface GraphNodeValues {
  /** Id du nœud, peut être vide à la création (sera alors imposé lorsque le nœud sera mis dans un graphe) */
  id?: string
  /** La section (son chemin relatif au dossier sections, vide pour un nœud fin) */
  section: string
  /** Un label facultatif, pour l’affichage dans editGraphe ou viewer */
  label?: string
  /** Un titre qui surchargera celui de la section s’il existe */
  title?: string
  /** Le nombre max d’exécutions (dès qu’il est atteint ça sort automatiquement) */
  maxRuns?: number
  /** La liste des branchements (obligatoire pour un nœud non fin) */
  connectors?: Connector[] | ConnectorValues[]
  /** Les paramètres du nœud (passés à la section pour l’initialiser) */
  params?: ParamsValues
  /** La position du Noeud dans l’éditeur de graphe */
  position?: PositionJson
}

/**
 * Les valeurs d’un GraphNode de section, pour le mettre dans un json par ex
 * Idem GraphNodeValues avec toutes les propriétés complétées (sauf position qui reste facultatif, pour se faciliter l’écriture manuelle des json)
 */
export interface GraphNodeActiveSerialized {
  id: string
  section: string
  label: string
  title: string
  maxRuns: number
  connectors: ConnectorSerialized[]
  /** Les paramètres du nœud (passés à la section pour l’initialiser) */
  params: ParamsValues
  /** La position du Noeud dans l’éditeur de graphe */
  position?: PositionSerialized
}

/**
 * Les valeurs d’un GraphNode de fin, pour le mettre dans un json par ex
 * Idem GraphNodeValues avec où toutes les propriétés complétées)
 */
export interface GraphNodeEndSerialized {
  id: string
  section: ''
  label: string
  /** La position du Noeud dans l’éditeur de graphe */
  position?: PositionSerialized
}

export type GraphNodeSerialized = GraphNodeActiveSerialized | GraphNodeEndSerialized

export interface GraphNodeActiveJson {
  section: string
  label: string
  title: string
  maxRuns: number
  connectors: ConnectorJson[]
  /** Les paramètres du nœud (passés à la section pour l’initialiser) */
  params: ParamsValues
  /** La position du Noeud dans l’éditeur de graphe */
  position?: PositionJson
}

/**
 * Les valeurs d’un GraphNode de fin, pour le mettre dans un json par ex
 * Idem GraphNodeValues avec où toutes les propriétés complétées)
 */
export interface GraphNodeEndJson {
  section: ''
  label: string
  /** La position du Noeud dans l’éditeur de graphe */
  position?: PositionJson
}

export type GraphNodeJson = GraphNodeActiveJson | GraphNodeEndJson

// on l’utilise pour du type narrowing sur les GraphNodeSerialized
export const isEndNode = (node: GraphNodeSerialized): node is GraphNodeEndSerialized => node.section === ''

/**
 * Un nœud du graphe
 */
class GraphNode {
  /** id du nœud */
  id: string
  /** Le nom de la section (vide pour un nœud fin) */
  section: string
  /** Titre affiché dans l’éditeur de graphe (et viewer) */
  label: string
  /** Titre affiché à l’éxécution (surcharge celui par défaut de la section) */
  title: string
  /** Le nombre max d’exécutions (dès qu’il est atteint ça sort automatiquement) */
  maxRuns: number
  /** Liste des branchements sortants */
  connectors: Connector[]
  /** Paramètres à passer à la section */
  params: ParamsValues
  /** Position du nœud dans l’éditeur */
  position: Position

  constructor ({
    id = '',
    section,
    label = '',
    title = '',
    maxRuns = MAX_RUNS_DEFAULT,
    connectors = [],
    params = {},
    position
  }: GraphNodeValues) {
    this.id = id
    this.section = section
    this.label = label
    this.title = title
    this.maxRuns = maxRuns
    this.connectors = []
    for (const connector of connectors) {
      if (connector.source == null) connector.source = this.id
      else if (connector.source !== this.id) throw Error(`Source ${connector.source} du connecteur ${connector.id} incohérent sur le nœud ${this.id}`)
      if (connector instanceof Connector) this.connectors.push(connector)
      else this.connectors.push(new Connector(connector))
    }
    this.params = params
    this.position = position instanceof Position ? position : new Position(position)
    // on valide à minima à la création (faudra être strict avant la sauvegarde)
    this.validate({ strict: false })
  }

  /**
   * Ajoute un branchement à la liste
   */
  addConnector (values: ConnectorValues | Connector): void {
    let connector
    if (values instanceof Connector) {
      if (values.source !== this.id) {
        console.error(Error(`source invalide, ${values.source} => ${this.id}`))
        values.source = this.id
      }
      connector = values
    } else {
      connector = new Connector({ ...values, source: this.id })
    }
    this.connectors.push(connector)
  }

  serialize (): GraphNodeSerialized {
    const { id, section, label, position: { x, y } } = this
    if (this.isEnd()) return { id, section, label, position: { x, y } } as GraphNodeEndSerialized
    const { title, maxRuns, params } = this
    // la seule prop à sérialiser (toutes les autres sont des types primitifs
    // => pas de pb de ref à l’objet initial qui serait transmise dans l’objet retourné)
    const connectors = this.connectors.map(c => c.serialize())
    return { id, section, label, title, maxRuns, connectors, params, position: { x, y } }
  }

  toJSON (): GraphNodeJson {
    const { section, label, position: { x, y } } = this
    if (this.isEnd()) return { section, label, position: { x, y } } as GraphNodeEndJson
    const { title, maxRuns, params } = this
    const connectors = this.connectors.map(c => c.toJSON())
    return { section, label, title, maxRuns, connectors, params, position: { x, y } }
  }

  /**
   * Retourne une liste d’avertissements éventuels (vide s’il n’y en a pas), pour des branchements
   */
  getWarnings (): string[] {
    const warnings: string[] = []
    if (!this.isEnd()) {
      if (Array.isArray(this.connectors)) {
        let foundAlwaysValid = false
        let i = 1
        for (const connector of this.connectors) {
          if (foundAlwaysValid) {
            warnings.push(`Le branchement de rang ${i}${this.label.length > 0 ? ` (${this.label})` : ''} ne sera jamais utilisée car elle arrive après un branchement sans condition `)
          } else if (connector.isAlwaysValid) {
            foundAlwaysValid = true
          }
          i++
        }
        if (!foundAlwaysValid) {
          warnings.push('Il n’y a aucun branchement sans condition (le dernier doit toujours l’être)')
        }
      } else {
        this.connectors = []
        warnings.push('Aucun branchement partant de ce nœud')
      }
    }
    return warnings
  }

  /**
   * Retourne true pour un nœud fin (donc section vide)
   */
  isEnd (): boolean {
    return isEndNode(this)
  }

  move (newPosition: Position): void {
    this.position = new Position({ x: newPosition.x, y: newPosition.y })
  }

  /**
   * throw une Error en cas de pb sur le Nœud
   * @param {boolean} [strict=true] Passer false pour tolérer un nœud (non fin) sans branchement ou sans branchement sans condition
   * @throws {Error} en cas de Node invalide
   */
  validate ({ strict = true }: { strict?: boolean } = {}): void {
    if (strict) {
      if (!this.isEnd() && (this.connectors.length === 0)) throw Error('Nœud sans branchement pour en sortir')
      if (this.connectors.some(b => b.isAlwaysValid)) throw Error('Il faut toujours au moins un branchement sans condition pour sortir d’un nœud')
      if (!Number.isInteger(this.maxRuns) || this.maxRuns < 1) throw Error(`maxRuns invalide : ${this.maxRuns}`)
    }
    // c’est le validate du graphe qui vérifie que les destinations existent
  }
}

export default GraphNode
