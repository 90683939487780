/** @module lib/core/checks */
import { loadBibli } from 'src/lib/core/bibli'
import loadSection, { isLegacySection } from 'src/lib/core/loadSection'
import type { RessourceJ3pParametresLax } from 'src/lib/editor/spEdit'
import GraphV1, { type GraphV1Values } from 'src/lib/entities/GraphV1'

import type {
  CheckResults, LegacyConnector,
  EditGrapheOptsV1,
  LegacyResultat,
  RessourceJ3pParametresV1,
  Resultat,
  SectionParameters,
  LegacyNodeParams,
  LegacyGraph,
  LegacyNode, RessourceJ3pBibli
} from 'src/lib/types'
import { stringify } from 'src/lib/utils/object'
import { getErrorMessage } from 'src/lib/utils/string'
import Graph, { type GraphValues } from '../entities/Graph'
import { convertResultat } from './convert1to2'

/**
 * Formate le retour d’un check pour toujours fournir les 3 props ok, errors, warnings
 * @param ok
 * @param errors
 * @param warnings
 */
export function formatCheckResult ({ ok, errors = [], warnings = [] }: Partial<CheckResults>): CheckResults {
  if (ok === true && errors.length > 0) throw Error(`Données incohérentes, ok vaut true mais il y a ${errors.length} erreur${errors.length > 1 ? 's' : ''} : ${errors.join(' | ')}`)
  ok = (errors.length === 0)
  return { ok, errors, warnings }
}

/**
 * Vérifie l’intégrité du graphe d’une ressource j3p
 * @param ressource Une ressource ou son rid
 * @todo virer ça et utiliser spValidate quand y'aura plus de v1 en base de données
 */
export async function checkRessource (ressource: Partial<RessourceJ3pBibli> | string): Promise<CheckResults> {
  const warnings: string[] = []
  const errors: string[] = []
  const consoleError = console.error
  const consoleWarn = console.warn
  console.warn = (...messages) => {
    warnings.push(messages.map(msg => typeof msg === 'object' ? stringify(msg) : String(msg)).join(' '))
  }
  // les vraies erreurs sont celles qui throw, ce qui sort en console.error passera en warning
  console.error = console.warn
  try {
    if (typeof ressource === 'string') {
      const result = await loadBibli(ressource)
      ressource = result.ressource
    }
    if (ressource.type !== 'j3p') throw Error('Cette ressource n’est pas de type j3p (exercice interactif)')
    if (ressource.parametres == null) throw Error('Ressource invalide (sans parametres)')
    let graph, warns: string[]
    if (isLegacyRessourceJ3pParametres(ressource.parametres)) {
      const { g: graphe, editgraphes } = ressource.parametres

      const values: GraphV1Values = { graphe }
      if (editgraphes?.positionNodes) values.positionNodes = editgraphes.positionNodes
      if (editgraphes?.titreNodes) values.titreNodes = editgraphes.titreNodes.map(t => typeof t === 'string' ? t : '')
      graph = new GraphV1(values)
      warns = graph.warnings
    } else {
      // format v2
      graph = new Graph(ressource.parametres.graph)
      warns = []
    }
    const result = await graph.validate()
    for (const e of result.errors) warnings.push(e)
    for (const w of result.warnings.concat(warns)) warnings.push(w)
  } catch (error) {
    errors.push(getErrorMessage(error))
  }
  console.error = consoleError
  console.warn = consoleWarn
  return { ok: errors.length === 0, errors, warnings }
}

/**
 * vérifie la cohérence des paramètres exportés par la section sectionName
 * @param sectionName
 */
export async function checkSectionExports (sectionName: string): Promise<CheckResults> {
  try {
    const exports = await loadSection(sectionName)
    if (isLegacySection(exports)) {
      const { convertParametres } = await import('./convert1to2.js')
      return checkParameters(convertParametres(exports.params))
    }
    return checkParameters(exports.parameters)
  } catch (error) {
    console.error(error)
    return formatCheckResult({ errors: [String(error)] })
  }
}

/**
 * Vérifie les paramètres exportés par une section
 * @param parameters
 */
export function checkParameters (parameters: SectionParameters): CheckResults {
  try {
    for (const [paramName, parameter] of Object.entries(parameters)) {
      const { type, defaultValue, help, controlledValues, multiple } = parameter
      if (help == null || help.length === 0) throw Error(`${paramName} n’a pas de propriété help`)
      // check cohérence values
      if (controlledValues != null && (!Array.isArray(controlledValues) || controlledValues.length < 1)) throw Error(`${paramName} précise values mais ce n’est pas un array non vide`)
      if (multiple === true && !Array.isArray(defaultValue)) throw Error(`${paramName} est multiple avec une defaultValue qui n’est pas un array`)

      // check cohérence de type pour defaultValue et l’éventuel controlledValues
      if (type === 'integer') {
        if (multiple) {
          for (const dv of defaultValue as (any)[]) {
            if (!Number.isInteger(dv)) throw Error(`${paramName} de type ${type} multiple a une des defaultValue (${dv}) qui n’est pas un entier`)
          }
        } else {
          if (!Number.isInteger(defaultValue)) throw Error(`${paramName} de type ${type} a une defaultValue (${defaultValue}) qui n’est pas un entier`)
        }
        if (controlledValues != null) {
          for (const cv of controlledValues) {
            if (!Number.isInteger(cv)) throw Error(`${paramName} de type ${type} a une controlledValue ($cv) qui n’est pas nu entier`)
          }
        }
      } else {
        if (multiple === true) {
          if (!Array.isArray(defaultValue)) throw Error(`${paramName} est multiple avec une defaultValue qui n’est pas un array`)
          // eslint-disable-next-line valid-typeof
          if (defaultValue.some(v => typeof v !== type)) throw Error(`${paramName} est multiple avec une des valeurs de defaultValue qui n’est pas de type ${type}`)
        } else {
          // eslint-disable-next-line valid-typeof
          if (typeof defaultValue !== type) throw Error(`${paramName} de type ${type} a une defaultValue qui est de type ${typeof defaultValue}`)
        }
        // eslint-disable-next-line valid-typeof
        if (Array.isArray(controlledValues) && !controlledValues.every(value => typeof value === type)) throw Error(`${paramName} précise values dont un des éléments n’est pas de type ${type}`)
      }
    }
    return formatCheckResult({ ok: true })
  } catch (error) {
    console.error(error)
    return formatCheckResult({ errors: [String(error)] })
  }
}

/**
 * Retourne true si le graphe ressemble à un graphe v1 (important pour l’inférence de type)
 */
export function isLegacyGraph (graph: LegacyGraph | GraphValues): graph is LegacyGraph {
  return Array.isArray(graph)
}
/** Retourne true si l’objet passé ressemble à un connecteur v1 (prop nn non vide) */
export function isLegacyConnector (obj: LegacyConnector | LegacyNodeParams): obj is LegacyConnector {
  return obj != null && typeof obj === 'object' && 'nn' in obj && obj.nn != null
}

/** Retourne true si node ressemble à un objet LegacyNode */
export function isLegacyNode (node: unknown) : node is LegacyNode {
  if (!Array.isArray(node)) return false
  if (![2, 3].includes(node.length)) return false
  const [id, sectionName, opts] = node
  return typeof id === 'string' && typeof sectionName === 'string' && (opts == null || Array.isArray(opts))
}

/**
 * Retourne true si ces params sont au format v1 (important pour l’inférence de type)
 */
export function isLegacyRessourceJ3pParametres (parametres: RessourceJ3pParametresLax): parametres is RessourceJ3pParametresV1 {
  if (parametres == null) throw Error('Paramètres invalides')
  return ('g' in parametres)
}

/**
 * Retourne true si le resultat est au format v1
 * @param resultat
 */
export function isLegacyResultat (resultat: Resultat | LegacyResultat): resultat is LegacyResultat {
  if (resultat == null || typeof resultat?.contenu !== 'object') throw Error('Résultat invalide')
  return ('graphe' in resultat.contenu)
}

/**
 * Retourne un Resultat (v2) à partir de données au format v1 ou v2
 */
export function normalizeResultat (laxResultat: Resultat | LegacyResultat): Resultat {
  if (isLegacyResultat(laxResultat)) return convertResultat(laxResultat)
  else return laxResultat as Resultat
}

/**
 * Retourne un objet Graph à partir d’un graphe v1|v2 (valeurs ou objet)
 * @param graphValues
 * @param [editgraphes]
 */
export async function normalizeGraph (graphValues: unknown, editgraphes?: EditGrapheOptsV1): Promise<Graph> {
  if (graphValues == null) throw Error('Graphe manquant')
  if (graphValues instanceof Graph) return graphValues
  if (isLegacyGraph(graphValues)) {
    const { convertGraph } = await import('src/lib/core/convert1to2')
    return convertGraph(graphValues, editgraphes)
  }
  return new Graph(graphValues)
}

export interface LaxOptions {
  /** Un graphe v1 ou v2  */
  graph?: GraphValues | Graph | LegacyGraph
  /** Un graphe v1 ou v2  */
  graphe?: GraphValues | Graph | LegacyGraph
  /** un résultat (v1|v2) contenant le graphe à reprendre */
  lastResultat?: Resultat | LegacyResultat
  editgraphes?: EditGrapheOptsV1
}

export interface NormalizedOptions {
  graph: Graph
  lastResultat?: Resultat
}

/**
 * Retourne graph/lastResultat en v2 à partir d’un mix v1/v2
 * @param options
 */
export async function normalizeOptions (options: LaxOptions): Promise<NormalizedOptions | null> {
  const graphValues = options.graph || options.graphe
  if (!graphValues) throw Error('Il faut fournir un graphe')
  let graph: Graph
  let lastResultat = options.lastResultat
  if (isLegacyGraph(graphValues)) {
    const { convertGraph, convertResultat } = await import('src/lib/core/convert1to2')
    graph = convertGraph(graphValues, options.editgraphes)
    if (lastResultat) {
      if (isLegacyResultat(lastResultat)) {
        lastResultat = convertResultat(lastResultat)
      } else {
        console.error(Error('format de LastResultat incohérent avec celui du graphe (v1)'))
        lastResultat = undefined
      }
    }
  } else {
    graph = new Graph(graphValues)
    if (lastResultat && isLegacyResultat(lastResultat)) {
      console.error(Error('format de LastResultat incohérent avec celui du graphe (v2)'))
      lastResultat = undefined
    }
  }
  return { graph, lastResultat }
}
