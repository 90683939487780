import Connector, { type ConnectorValues, type ScoreComparator } from 'src/lib/entities/Connector'
import Graph from 'src/lib/entities/Graph'
import GraphNode, { type ParamsValues, type ParamValue } from 'src/lib/entities/GraphNode'
import Position from 'src/lib/entities/Position'
import Result from 'src/lib/entities/Result'

import type {
  CheckResults,
  EditGrapheOptsV1,
  LegacyConnector,
  LegacyGraph,
  LegacyNode,
  LegacyNodeParams,
  LegacyResultat,
  LegacySectionParams,
  RessourceJ3pBibli,
  Resultat,
  SectionEvaluations,
  SectionParameter,
  SectionParameterEditor,
  SectionParameters,
  SectionParameterType,
  SectionParameterValidate
} from 'src/lib/types'
import { stringify } from 'src/lib/utils/object'
import { getErrorMessage, getNonEmptyString } from 'src/lib/utils/string'
import { isLegacyConnector, isLegacyRessourceJ3pParametres } from './checks'

const reNumCond = /^([<>]=?)([0-9.]+)$/

export const params2byName1: Record<string, string> = {
  nbrepetitions: 'nbQuestions',
  nbchances: 'maxTries',
  nbetapes: 'nbSteps'
}

/**
 * Converti les données d’un branchement v1 (score & pe) en objet Connector (max est traité dans convertNode)
 * @private
 * @param connecteur Un branchement
 * @param source L’id du nœud source
 * @return {Connector}
 */
function convertConnector (connecteur: LegacyConnector, source: string): Connector {
  const connectorValues: ConnectorValues = {
    source,
    target: String(connecteur.nn),
    feedback: connecteur.conclusion ?? '' // pas sûr que conclusion existe toujours
  }
  try {
    // on passe à la condition
    // check 'sans condition' (parfois avec espace et parfois avec +)
    if (typeof connecteur.score !== 'string' && typeof connecteur.pe !== 'string') { // Tout d’abord on vérifie qu’il y a bien au moins score ou pe
      throw TypeError(`branchement invalide (aucune condition sur score ou pe avec : ${stringify(connecteur)}) `)
    }
    if (
      (typeof connecteur.score === 'string' && /^sans.condition$/i.test(connecteur.score)) ||
      (typeof connecteur.pe === 'string' && /^sans.condition$/i.test(connecteur.pe))
    ) {
      connectorValues.typeCondition = 'none'
    } else if (typeof connecteur.score === 'string' && ['<=1', '>=0'].includes(connecteur.score)) {
      console.warn(`Condition score ${connecteur.score} toujours vraie => converti en « sans condition »`)
      connectorValues.typeCondition = 'none'
    } else if (typeof connecteur.score === 'string') {
      const chunks = reNumCond.exec(connecteur.score)
      if (chunks != null) {
        connectorValues.scoreComparator = chunks[1] as ScoreComparator
        connectorValues.scoreRef = Number(chunks[2]?.replace(',', '.'))
      } else {
        console.warn(`Condition ${connecteur.score} invalide => converti en « sans condition »`)
        connectorValues.typeCondition = 'none'
      }
      // on passe à l’analyse de la pe
    } else if (connecteur.pe != null && ['>=0', '<=1'].includes(connecteur.pe)) {
      console.warn(`Condition pe ${connecteur.pe} invalide => converti en « sans condition »`)
      connectorValues.typeCondition = 'none'
    } else if (typeof connecteur.pe === 'string') {
      const chunks = reNumCond.exec(connecteur.pe)
      if (chunks != null) {
        console.warn(`Condition pe${connecteur.pe} invalide => converti en condition sur le score`)
        connectorValues.scoreComparator = chunks[1] as ScoreComparator
        connectorValues.scoreRef = Number(chunks[2])
      } else if (connecteur.pe.includes(',')) {
        // c’est une liste d’ids de pe avec le séparateur , (à priori sans crochets)
        connectorValues.peRefs = connecteur.pe
          .split(',') // on découpe avec le séparateur ,
          .map((value: string) => {
            // on regarde l’id de pe obtenu pour chaque morceau, si c’est bien composé uniquement de ces caractères on le garde tel quel
            if (/^[a-zA-Z0-9_-]+$/.test(value)) return value
            // sinon on vire tous les caractères bizarres
            const newValue: string = value.replace(/[^a-zA-Z0-9_-]/g, '')
            // et on râle
            console.warn(Error(`valeur de pe incorrecte : ${value} => ${newValue}`))
            // avant de retourner la valeur néttoyée
            return newValue
          })
          .filter(Boolean) // vire d’éventuelles chaînes devenues vides
      } else {
        // une seule pe, on veut toujours une liste
        connectorValues.peRefs = [connecteur.pe]
      }
    } else { // normalement on a déjà traîté l’absence de connecteur.score et connecteur.pe au tout début, c’est donc un garde-fou sans doute inutile...
      throw TypeError(`branchement invalide (aucune condition sur score ou pe avec : ${stringify(connecteur)}) `)
    }
    // max & snn sont traités dans convertNode, car il faut alors ajouter un connecteur de plus
    return new Connector(connectorValues)
  } catch (error) {
    console.error(error, '\navec les datas:\n', connectorValues, '\ndu branchement:\n', connecteur)
    throw error
  }
}

/**
 * Convertit un nœud v2 en GraphNode v2
 * @throws {Error} Si y’a pas d’id ou de section, ou que ce n’est pas un nœud fin et que les options (branchements + params) ne sont pas un tableau (plante pas avec un tableau vide)
 */
function convertNode (node1: LegacyNode, endNodeId: string): GraphNode {
  let [id, section, opts] = node1
  if (typeof id === 'number' && Number.isInteger(id) && id > 0) id = String(id)
  if (typeof id !== 'string') throw TypeError('id doit être une string (ou éventuellement un number entier positif)')
  if (typeof section !== 'string') throw TypeError(`section doit être une string (${typeof section} pour le nœud d’id ${id})`)
  if (section.toLowerCase() === 'fin') {
    return new GraphNode({ id, section: '' })
  }
  if (!Array.isArray(opts)) opts = [{}]
  // les paramètres sont forcément en dernier (tous les autres sont des branchements), mais ils sont facultatifs
  // on les ajoute si ça manque (si on trouve une propriété nn sur le dernier c’est un branchement)
  const connectors: Connector[] = []
  let params: LegacyNodeParams = {}
  let maxRuns: number | undefined
  for (const opt of opts) {
    if (isLegacyConnector(opt)) {
      // opt est un branchement, on clone pour ne pas modifier le node1 fourni
      const br = { ...opt }
      if (typeof br.nn === 'number') br.nn = String(br.nn)
      // on change les target fin
      if (br.nn?.toLowerCase() === 'fin') br.nn = endNodeId
      if (typeof br.snn === 'number') br.snn = String(br.snn)
      if (br.snn?.toLowerCase() === 'fin') br.snn = endNodeId
      // si on trouve du max|maxParcours il faut insérer un branchement avec maxRuns juste avant
      if (br.max != null || br.maxParcours != null) {
        // faut ajouter un 2e connector avec du nbRuns (on change la logique de passage dans le connecteur par nb d’exécution du node source,
        // c’est pas tout à fait pareil mais c’est ce qu’on a de plus proche dans la nouvelle logique)
        const max = br.max != null ? Number(br.max) : Number(br.maxParcours)
        if (!Number.isInteger(max) || max < 1) throw Error(`propriété max ou maxParcours invalide dans le branchement ${stringify(opt)}`)
        connectors.push(new Connector({
          source: id,
          nbRuns: max,
          target: String(br.snn),
          typeCondition: 'nbRuns',
          feedback: br.sconclusion // pas de cast en string pour éviter de gérer le "undefined", ce sera vérifié à l’instanciation de l’objet
        }))
        // et faut alors augmenter maxRuns
        if (!maxRuns || maxRuns <= max) maxRuns = max + 1
      }
      // on peut ajouter le connecteur "ordinaire"
      connectors.push(convertConnector(br, id))
    } else {
      // opt est l’objet params du node
      params = convertGraphParams(opt)
    }
  }
  return new GraphNode({ id, section, connectors, maxRuns, params })
}

/**
 * Converti un graphe v1 (tableau de tableaux) en graphe v2 (objet Graph)
 * Ça lance un validate
 * @param graphe
 * @param [editGrapheOptsV1]
 */
export function convertGraph (graphe: LegacyGraph, editGrapheOptsV1?: EditGrapheOptsV1): Graph {
  if (!Array.isArray(graphe)) throw Error('graphe v1 invalide (pas un tableau)')
  if (graphe.length === 0) return new Graph()
  const positionNodes = [...(editGrapheOptsV1?.positionNodes ?? [])]
  const titreNodes = [...(editGrapheOptsV1?.titreNodes ?? [])]
  // les valeurs pour initialiser le graphe v2
  const errors: string[] = []
  const graphValues: Partial<Graph> = {}
  graphValues.nodes = {}
  let startingId = '' // on prendra le premier nœud non fin
  let rang = 0
  // on cherche d’abord un nœud fin
  let firstEndNode = graphe.find(n => n?.[1]?.toLowerCase() === 'fin')
  if (!firstEndNode) {
    let id = 'fin'
    let i = 1
    while (graphe.some(n => n[0] === id)) id = `fin${i++}`
    firstEndNode = [id, '']
    graphe.push(firstEndNode)
  }
  const firstEndNodeId = firstEndNode[0]

  for (const n of graphe) {
    // @ts-expect-error car pour ts n.length vaut au moins deux (c’est ce qu’on lui a dit avec notre type LegacyGraph, trop compliqué de gérer ce cas du premier élément qui pourrait être vide en typant)
    if (rang === 0 && Array.isArray(n) && (n.length === 0)) {
      // un tableau vide en premier, on le zappe, mais faut regarder aussi les positions et titres
      if (graphe.length === positionNodes.length) positionNodes.shift()
      if (graphe.length === titreNodes.length) titreNodes.shift()
      continue
    }
    // @ts-expect-error car n.length peut pas être nul, mais on le teste car on est appelé par des trucs qui respectent pas forcément nos types
    if (!Array.isArray(n) || (n.length === 0)) {
      errors.push(`nœud d’index ${rang} invalide (pas un tableau)`)
      continue
    }
    // rang n’a pas encore été incrémenté, c’est l’index
    const title = titreNodes[rang]
    const label = getNonEmptyString(title ?? '', `Nœud ${rang + 1}`)
    const [x, y] = positionNodes[rang] ?? []
    rang++
    try {
      const graphNode = convertNode(n, firstEndNodeId)
      // et on lui ajoute ces valeurs qui ont été laissées à leur valeur par défaut
      graphNode.label = label
      graphNode.position = new Position({ x, y })
      graphValues.nodes[graphNode.id] = graphNode
      // on prend le 1er nœud non fin comme nœud de départ
      if (startingId === '' && !graphNode.isEnd()) startingId = graphNode.id
    } catch (error) {
      errors.push(`Erreur avec le nœud de rang ${rang} : ${getErrorMessage(error)}`)
    }
  }
  if (startingId !== '') graphValues.startingId = startingId
  else errors.push('graphe vide')
  if (errors.length > 0) throw Error(errors.join('\n'))
  const graph = new Graph(graphValues)
  graph.complete()
  const { errors: validateErrors, warnings } = graph.validateSync({ clean: true, strict: false })
  if (validateErrors.length) console.error(`Erreur${validateErrors.length > 1 ? 's' : ''} à la conversion du graphe :\n  - ${errors.join('\n  - ')}`)
  if (warnings.length) console.warn(`Avertissement${errors.length > 1 ? 's' : ''} à la conversion du graphe :\n  - ${warnings.join('\n  - ')}`)
  return graph
}

/**
 * Convertit les paramètres d’un node (v1 => v2, les valeurs restent identiques mais les noms peuvent changer, par ex nbrepetitions => nbQuestions)
 * @param params
 */
function convertGraphParams (params: LegacyNodeParams): ParamsValues {
  const paramsV2: ParamsValues = {}
  for (const [name1, value] of Object.entries(params)) {
    const name2 = params2byName1[name1] ?? name1
    paramsV2[name2] = value
  }
  return paramsV2
}

/**
 * Transforme l’export params.parametres des sections v1 en parameters des sections v2
 * @param params
 */
export function convertParametres (params?: LegacySectionParams): SectionParameters {
  const emptyParameters = {
    title: {
      type: 'string',
      defaultValue: ''
    }
  }
  if (params?.parametres == null) {
    return emptyParameters
  }
  const parameters: SectionParameters = emptyParameters
  for (let [id, defaultValue, type1, help, options] of params.parametres) {
    if (typeof id !== 'string') throw Error(`Identifiant de paramètre invalide ${typeof id}`)
    const paramName = params2byName1[id] ?? id

    if (typeof type1 !== 'string') throw Error(`Type de paramètre invalide (${typeof type1})`)

    const typeOfDefaultValue = (): 'string' | 'number' | 'boolean' => {
      const t = Array.isArray(defaultValue)
        ? typeof defaultValue[0]
        : typeof defaultValue
      if (['number', 'string', 'boolean'].includes(t)) return t as 'string' | 'number' | 'boolean'
      console.error(Error(`Le type de la valeur par défaut du param ${paramName} est incorrect : ${t}`))
      return 'string'
    }

    if (typeof help !== 'string') help = ''
    let type: SectionParameterType | null = null
    let multiple = false
    let editor: SectionParameterEditor<SectionParameterType> | null = null
    let validate: SectionParameterValidate<SectionParameterType, boolean> | null = null
    const controlledValues: string[] = []
    // cf Parcours.prototype._initParametres pour la liste des types v1 gérés
    if (['entier', 'integer'].includes(type1)) {
      type = 'integer'
      defaultValue = Number(defaultValue)
    } else if (['number', 'reel'].includes(type1)) {
      type = 'number'
      defaultValue = Number(defaultValue)
    } else if (['string'].includes(type1)) {
      // array est utilisé pour une string qui doit être au format d’un array jsonifié
      if (typeof defaultValue !== 'string') throw Error(`Paramètre de type string avec une valeur par défaut qui n’est pas une string (${typeof defaultValue})`)
      type = 'string'
    } else if (['boolean'].includes(type1)) {
      defaultValue = typeof defaultValue === 'string' ? defaultValue === 'true' : Boolean(defaultValue)
      type = 'boolean'
    } else if (['liste'].includes(type1)) {
      if (!Array.isArray(options)) throw Error(`Options invalides pour un type ${type1} : ${stringify(options)}`)
      type = 'string'
      for (const option of options) controlledValues.push(String(option))
      if (controlledValues.length === 0) throw Error('Il faut fournir des valeurs pour le type liste')
      defaultValue = String(defaultValue)
    } else if (['array'].includes(type1)) {
      // il s’agit d’un paramètre dont la saisie doit être du json qui se convertit en tableau (array de string|number|boolean), mais y’a pas d’option
      if (typeof defaultValue === 'string') {
        try {
          defaultValue = JSON.parse(defaultValue)
          console.warn('paramètre de type array avec une valeur par défaut de type string')
        } catch (error) {
          console.error(error)
          throw Error(`type array avec une valeur par défaut invalide : ${String(defaultValue)}`)
        }
      }
      if (!Array.isArray(defaultValue)) throw Error(`type array avec une valeur par défaut qui n’est pas un array : ${typeof defaultValue}`)
      // attention, defaultValue peut être un tableau vide !
      if (defaultValue.length) {
        type = typeOfDefaultValue() // ce sera string|number|boolean, pas d’integer possible (on peut pas savoir, même si tous sont des entiers la section peut accepter des number)
      } else {
        // on peut pas deviner, on choisit arbitrairement ça
        type = 'string'
      }
      multiple = true
    } else if (type1 === 'intervalle') {
      type = 'string'
      validate = (value: ParamValue): Promise<CheckResults> => {
        const v = typeof value === 'string' ? value : String(value)
        const ok = /^\[[0-9]+([.,][0-9]+)?;[0-9]+([.,][0-9]+)?]$/.test(v)
        const warnings: string[] = []
        const errors: string[] = []
        if (!ok) errors.push(`« ${value} » n’est pas un intervalle valide`)
        return Promise.resolve({ ok, warnings, errors })
      }
    } else if (type1 === 'editor') {
      type = typeOfDefaultValue()
      if (typeof options !== 'function') throw Error(`Fonction manquante pour le type editor (${paramName})`)
      editor = options as SectionParameterEditor<SectionParameterType>
    }
    if (type == null) {
      throw Error(`Type de paramètre invalide (${String(type1)})`)
    }
    // on vérifie ce qu’on vient de convertir
    if (!['string', 'number', 'integer', 'boolean'].includes(type)) {
      console.error(Error(`La conversion de type a échoué, on tombe sur ${type} pour ${paramName} => string`))
      type = 'string'
    }
    const parameter: SectionParameter<typeof type, typeof multiple> = {
      type,
      defaultValue,
      help,
      multiple
    }
    parameters[paramName] = parameter
    // et on lui ajoute les fcts éventuelles
    if (editor != null) parameter.editor = editor
    if (validate != null) parameter.validate = validate
    if (controlledValues.length > 0) parameter.controlledValues = controlledValues
  }
  // console.log('La conversion de', params.parametres, 'donne', parameters)
  return parameters
}

/**
 * Converti les anciennes pe au nouveau format (evaluations)
 * @param params
 */
export function convertPes (params: LegacySectionParams): SectionEvaluations {
  const evaluations: SectionEvaluations = {}
  if (Array.isArray(params.pe)) {
    for (const p of params.pe) {
      for (const [k, v] of Object.entries(p)) {
        evaluations[k] = typeof v === 'string' ? v : String(v)
      }
    }
  }
  return evaluations
}

export function convertResultat (resultat: LegacyResultat): Resultat {
  const editgraphe: EditGrapheOptsV1 = resultat.contenu.editgraphes ?? { positionNodes: [], titreNodes: [] }
  const graph = convertGraph(resultat.contenu.graphe, editgraphe)
  const results: Result[] = []
  // On remplit les results et on en profite pour trouver l’id du dernier noeud fait.
  let lastId: string = '1'
  for (const bilan of resultat.contenu.bilans) {
    const id = bilan.id
    const score = bilan.score
    // ne pas virer le typeof bilan.pe === 'string' (qui semble superflu à ts)
    const hasPe = typeof bilan.pe === 'string' && !/^[0-9., -]*$/.test(bilan.pe)
    const evaluation = (hasPe && bilan.pe) || ''
    // tous les results auront la même date car on distinguait pas les dates par nœud
    const date = resultat.date instanceof Date ? resultat.date.toJSON() : resultat.date
    const duree = Number(resultat.duree)
    results.push({ id, score, evaluation, date, duree })
    lastId = id
  }

  const persistentStorage = resultat.contenu.donneesPersistantes ?? {}
  const runs: [string, number][] = []
  for (const bilan of resultat.contenu.bilans) {
    const id = bilan.id
    const index = runs.findIndex((el) => el[0] === id)
    if (index < 0) {
      runs.push([id, 1])
    } else {
      const run = runs[index]
      const nbRuns = run != null ? (run[1] ?? 0) + 1 : 1
      runs[index] = [id, nbRuns]
    }
  }
  const nbRuns = Object.fromEntries(runs)
  return {
    ...resultat,
    date: resultat.date instanceof Date ? JSON.stringify(resultat.date) : resultat.date,
    contenu: {
      pathway: {
        graph,
        currentNodeId: lastId,
        persistentStorage,
        results,
        nbRuns,
        startedAt: JSON.stringify(resultat.date)
      }
    },
    type: 'j3p',
    reponse: ''
  }
}

/**
 * Retourne un objet Graph (v2) à partir d’une ressource
 * @param ressource
 * @throws {Error} en cas de pb
 */
export function getGraphFromResource ({ type, parametres, rid }: RessourceJ3pBibli): Graph {
  if (type !== 'j3p') {
    throw Error('getGraphFromResource ne traite que les ressources de type j3p')
  }
  if (isLegacyRessourceJ3pParametres(parametres)) {
    if (typeof parametres?.g !== 'object' || typeof parametres.editgraphes?.positionNodes !== 'object' || typeof parametres.editgraphes?.titreNodes !== 'object') {
      throw Error('parametres invalides')
    }
    return convertGraph(parametres.g, parametres.editgraphes)
  }
  // parametres au format v2
  if (!parametres.graph) throw Error(`Ressource ${rid} sans graphe`)
  return new Graph(parametres.graph)
}
