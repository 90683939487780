import * as notie from 'notie'
import { pesList } from 'src/lib/core/loadSection'
import { MAX_RUNS_DEFAULT } from 'src/lib/entities/GraphNode'
import { checkArray, checkScore, checkString } from 'src/lib/utils/validators'

import { v4 as uuid } from 'uuid'

// il faut définir cette fct comme ça pour garantir le type à l’intérieur d’un if (isArrayNotEmpty),
// dire à TS que si ça répond true c’est parce que l’argument est un array d’éléments
// tous du même type générique T (imposé par ce qu’on passe à la fct)
// function isArrayNotEmpty<T> (arr?: T[]): arr is T[] {
//   return arr != null && arr.length > 0
// }

export type TypeCondition = 'none' | 'pe' | 'score' | 'nbRuns'
export const conditions = ['none', 'pe', 'score', 'nbRuns']

// SceneUI se base dessus pour ajouter une classe css
export const noConditionLabel = 'Sans condition'

interface Transition {
  nextId: string
  feedback: string
}

/**
 * Les opérateurs possibles sur un score (propriété scoreComparator d’un objet {@link Connector})
 */
export const scoreComparators: string[] = ['=', '<', '<=', '>', '>=']

/**
 * Type d’un scoreComparators (string restreinte aux comparateurs gérés)
 */
export type ScoreComparator = typeof scoreComparators[number]

/**
 * Type accepté par le constructeur
 */
export interface ConnectorValues {
  id?: string
  target: string
  feedback?: string
  label?: string
  scoreComparator?: ScoreComparator
  peRefs?: string[]
  nbRuns?: number
  source?: string
  scoreRef?: number
  typeCondition?: TypeCondition
}

interface ConnectorBaseSerialized extends ConnectorValues {
  // ça c’est toujours obligatoire
  id: string
  target: string
  feedback: string
  label: string
  // le reste dépend du type de condition
}

export interface ConnectorNoneSerialized extends ConnectorBaseSerialized {
  typeCondition: 'none'
}

export interface ConnectorPeSerialized extends ConnectorBaseSerialized {
  typeCondition: 'pe'
  peRefs: string[]
}

export interface ConnectorScoreSerialized extends ConnectorBaseSerialized {
  typeCondition: 'score'
  scoreComparator: ScoreComparator
  scoreRef: number
}

export interface ConnectorNbRunsSerialized extends ConnectorBaseSerialized {
  typeCondition: 'nbRuns'
  nbRuns: number
}

/**
 * Représentation en PlainObject d’un Connector (pour redux)
 */
export type ConnectorSerialized =
  ConnectorNoneSerialized
  | ConnectorNbRunsSerialized
  | ConnectorPeSerialized
  | ConnectorScoreSerialized
/**
 * Export Json d’un Connector (ConnectorSerialized sans id)
 */
export type ConnectorJson = Omit<ConnectorSerialized, 'id' | 'source'>

interface ConnectorValidateOptions {
  /**
   * précise le contexte de l’erreur éventuelle (par exemple l’id du noeud source)
   */
  errorPrefix?: string
}

/**
 * Un branchement entre deux nœuds d’un graphe
 * (un élément du tableau connectors d’un GraphNode)
 */
class Connector {
  /**
   * Id du nœud destination (vers lequel on ira si la condition est remplie)
   */
  target: string
  /**
   * Message affiché lors du passage dans ce connecteur avant d’aller vers target
   */
  feedback: string
  /**
   * Le label affiché dans l’éditeur pour désigner le connecteur
   * @default ''
   */
  label: string

  /**
   * Comparateur à utiliser sur le score
   */
  scoreComparator: ScoreComparator
  /**
   * Liste des pe (leur id, en général pe_nn) qui valident la condition de ce connecteur quantitatif (si non vide les conditions de score sont ignorées)
   */
  peRefs: string[]
  /**
   * Pour le type de condition nbRuns, le nb max de fois où le nœud source a été exécuté pour valider ce branchement
   */
  nbRuns: number
  /**
   * Id du connector (init à la création avec un uuid)
   */
  id: string
  /**
   * Id du node duquel part ce connector
   */
  source: string

  constructor ({
    id,
    target,
    feedback,
    label,
    scoreComparator,
    scoreRef,
    peRefs,
    nbRuns,
    source,
    typeCondition
  }: ConnectorValues) {
    // on initialise l’id s’il n’est pas fourni
    this.id = id ?? uuid()
    this.source = source ?? ''

    /** Nœud destination */
    this.target = target
    /** feedback affiché quand la condition est remplie */
    this.feedback = feedback ?? ''
    /** Label affiché sur le connecteur dans editgraphe */
    this.label = label ?? ''
    // this.isAlwaysValid = Boolean(isAlwaysValid) On vire et on remplace par this.typeCondition
    /** les pe éventuelles qui valident le connecteur nécessaire avant de fixer typeCondition à 'pe' sinon ça provoque une erreur */
    this.peRefs = peRefs ?? []
    /** type de condition du connecteur entre 'none', 'pe', 'nbRuns' et 'score' */
    this._typeCondition = typeCondition ?? 'none'
    // pour la condition, on accepte d’affecter des valeurs qui seront finalement ignorées (dans l’éditeur elles seront grisées mais présentes,
    // ça évite de tout refaire si pendant la construction on coche "sans condition" puis qu’on le décoche)
    /** Nombre max de passage dans ce connecteur */
    /** comparateur de score (≥ par défaut) */
    this.scoreComparator = scoreComparator ?? '>='
    /** référence de comparaison du score */
    this._scoreRef = scoreRef ?? 0
    /** Nb max d’exécution du nœud source (entier positif) */
    this.nbRuns = (nbRuns && Number.isInteger(nbRuns)) ? nbRuns : MAX_RUNS_DEFAULT
    if (typeCondition == null) {
      if (this.peRefs.length === 0) {
        if (this.scoreComparator === '>=' && this.scoreRef === 0) {
          this._typeCondition = 'none'
        } else if (this.scoreComparator === '<=' && this.scoreRef === 1) {
          notie.alert({
            type: 'warning',
            text: 'une condition sur un score<=1 revient à « sans condition »',
            stay: false,
            time: 3,
            position: 'top'
          })
          this._typeCondition = 'none'
        } else if (this.scoreComparator != null && this.scoreRef != null) {
          this._typeCondition = 'score'
        } else {
          this._typeCondition = 'nbRuns'
        }
      } else {
        this._typeCondition = 'pe'
      }
    }
    // on ne valide pas à la création (faudra le faire avant la sauvegarde)
  }

  private _scoreRef: number

  /**
   * Valeur à laquelle comparer le score
   */
  get scoreRef (): number {
    return this._scoreRef
  }

  set scoreRef (value) {
    if (!Number.isFinite(value) || value < 0 || value > 1) throw Error(`Score de référence invalide : ${String(value)}`)
    this._scoreRef = value
  }

  private _typeCondition: TypeCondition

  get typeCondition (): TypeCondition {
    return this._typeCondition
  }

  set typeCondition (newType) {
    if (conditions.includes(newType)) this._typeCondition = newType
    else throw Error(`Type de condition invalide ${String(newType)}`)
  }

  get isAlwaysValid (): boolean {
    return this._typeCondition === 'none'
  }

  get isTypePe (): boolean {
    return this._typeCondition === 'pe'
  }

  get isTypeScore (): boolean {
    return this._typeCondition === 'score'
  }

  get isTypeNbRuns (): boolean {
    return this._typeCondition === 'nbRuns'
  }

  /**
   * retourne la condition formatée en HTML
   * @param connector
   * @param section
   */
  static async getCondition (connector: ConnectorValues | Connector, section: string): Promise<string> {
    const { typeCondition, peRefs, nbRuns, scoreRef, scoreComparator } = connector
    switch (typeCondition) {
      case 'none':
        return noConditionLabel
      case 'score': {
        const comp = scoreComparator === '>=' ? '≥' : scoreComparator === '<=' ? '≤' : scoreComparator
        return `Si score ${comp}${scoreRef}`
      }
      case 'nbRuns':
        if (nbRuns == null) throw Error('Condition sur le maximum d’exécution sans préciser combien')
        return `${nbRuns} exécution${(nbRuns) > 1 ? 's' : ''} max`
      case 'pe': {
        if (peRefs?.length == null) throw Error(`Condition sur l’évaluation qualitative sans aucune évaluation sélectionnée (connecteur ${connector.label}, ${connector.id})`)
        const list = await pesList(section, peRefs)
        return 'Si : «&nbsp;' + list.join('&nbsp;» ou «&nbsp;') + '&nbsp;»'
      }
    }
    throw Error(`Pas de condition valide sur le connecteur ${connector.label} (${connector.id})`)
  }

  /**
   * retourne un objet contenant les différentes erreurs rencontrées
   * @param connector
   * @param options
   */
  static getErrors (connector: Connector | ConnectorValues, options: ConnectorValidateOptions = {}): Record<string, string> {
    const errors: Record<string, string> = {}
    const { errorPrefix = 'Branchement invalide' } = options
    const checkProp = (prop: keyof ConnectorValues): void => checkString(connector[prop], `${errorPrefix} : valeur de ${prop}`, true)
    checkProp('target')
    if (connector.target === '') {
      errors.target = `${errorPrefix} : nœud de destination manquant`
    }
    if (connector.typeCondition === 'nbRuns') {
      // si c’est le cas, alors l’input devrait avoir un min de 1
      if (connector.nbRuns == null || !Number.isInteger(connector.nbRuns) || connector.nbRuns <= 0) {
        errors.nbRuns = `${errorPrefix} : nbRuns ${String(connector.nbRuns)} incorrect`
      }
    }

    if (connector.typeCondition === 'score') {
      if (connector.scoreComparator == null || !scoreComparators.includes(connector.scoreComparator)) {
        errors.scoreComparator = `${errorPrefix} : scoreComparator ${connector.scoreComparator} incorrect ou absent`
      }
      if (typeof connector.scoreRef === 'number') {
        try {
          checkScore(connector.scoreRef, `${errorPrefix} scoreRef`)
        } catch (error: any) {
          errors.scoreRef = error instanceof Error ? error.message : error.toString()
        }
      } else {
        errors.scoreRef = 'Il faut préciser un score de référence pour une comparaison sur le score'
      }
    } else if (connector.typeCondition === 'pe') {
      try {
        checkArray(connector.peRefs, `${errorPrefix} peRefs`, { notEmpty: true, arrayOf: 'string', allDef: true })
      } catch (error: any) {
        errors.peRefs = error instanceof Error ? error.message : error.toString()
      }
    }
    return errors
  }

  /**
   * Retourne true si le branchement se déclenche sur le score
   */
  hasScoreCondition (): boolean {
    return scoreComparators.includes(this.scoreComparator) && this.scoreRef >= 0 && this.scoreRef <= 1
  }

  isScoreCondition (): boolean {
    return this.typeCondition === 'score' && this.hasScoreCondition()
  }

  hasPeCondition (): boolean {
    return Array.isArray(this.peRefs) && this.peRefs.length > 0 && this.peRefs.every(peRef => Boolean(peRef))
  }

  isPeCondition (): boolean {
    return this.typeCondition === 'pe' && this.hasPeCondition()
  }

  isNbRunsCondition (): boolean {
    return this.typeCondition === 'nbRuns'
  }

  /**
   * Retourne true ou throw une Error
   * @throws {Error} En cas d’incohérence dans les propriétés de l’objet
   */
  validate (options: ConnectorValidateOptions = {}) {
    const errors: Record<string, string> = Connector.getErrors(this, options)
    const errorsMessage: string = Object.entries(errors).map(([prop, error]) => `${prop} : ${error}`).join(',\n')
    if (errorsMessage !== '') throw Error(errorsMessage)
  }

  /**
   * Retourne l’id du prochain nœud et le feedback à afficher, si ce branchement est validé par les paramètres, null sinon
   * @param score
   * @param nbRuns
   * @param [pe]
   */
  getTransition (score: number, nbRuns: number, pe = ''): Transition | null {
    const match = this.isAlwaysValid || nbRuns >= this.nbRuns || this.peRefs.includes(pe) || this._scoreMatch(score)
    if (!match) return null
    return { nextId: this.target, feedback: this.feedback }
  }

  serialize (): ConnectorSerialized {
    const { feedback, typeCondition, label, peRefs, scoreComparator, scoreRef, nbRuns, target, id, source } = this
    switch (typeCondition) {
      case 'nbRuns':
        return { id, feedback, typeCondition, label, source, target, nbRuns }
      case 'score':
        return { id, feedback, typeCondition, label, source, target, scoreRef, scoreComparator }
      case 'pe':
        return { id, feedback, typeCondition, label, source, target, peRefs }
      case 'none':
        return { id, feedback, typeCondition, label, source, target }
    }
    throw Error(`type de condition ${typeCondition} non géré dans serialize()`)
  }

  /**
   * Retourne un Object prêt à être mis en json
   * @return {ConnectorJson}
   */
  toJSON (): ConnectorJson {
    const { feedback, typeCondition, label, nbRuns, target, peRefs, scoreComparator, scoreRef } = this
    switch (typeCondition) {
      case 'nbRuns':
        return { feedback, typeCondition, label, target, nbRuns }
      case 'score':
        return { feedback, typeCondition, label, target, scoreRef, scoreComparator }
      case 'pe':
        return { feedback, typeCondition, label, target, peRefs }
      case 'none':
        return { feedback, typeCondition, label, target }
    }
    throw Error(`type de condition ${typeCondition} non géré dans toJSON()`)
  }

  /**
   * Retourne true si le score passé valide ce branchement
   * @param score
   */
  _scoreMatch (score: number): boolean {
    const r = this.scoreRef
    switch (this.scoreComparator) {
      case '=':
        return Math.abs(score - r) < 1e-10
      case '<':
        return score < r
      case '<=':
        return score <= r
      case '>':
        return score > r
      case '>=':
        return score >= r
    }
    throw Error(`Erreur interne, comparateur ${this.scoreComparator} non géré`)
  }
}

export default Connector
